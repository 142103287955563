.carousel {
	display: flex;
}

.thumbnail-column {
	display: flex;
	flex-direction: column;
	width: 20%;
}

.thumbnail {
	cursor: pointer;
	margin-bottom: 10px;
	transition: transform 0.3s;
	width: 100px; /* Adjust size as needed */
	height: auto;
}

.thumbnail:hover {
	transform: scale(1.1);
}

.thumbnail.active {
	border: 2px solid blue; /* Highlight the active thumbnail */
}

.main-image {
	width: 80%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.main-image img {
	max-width: 100%;
	max-height: 80vh;
}
