@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda&family=Lora&display=swap");

body {
	margin: 0;
	/* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	/* font-family: "Lora", serif; */
	font-family: "Bodoni Moda", serif;
	font-family: "Lora", serif;
}

h1,
h2,
h3 {
	font-family: "Bodoni Moda", serif;
}

body > div {
	height: 100%;
	overflow: scroll;
	-webkit-overflow-scrolling: touch;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

*:focus {
	outline: none;
}
